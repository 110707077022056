<template>
    <div>
        <br><br>
        <div class="about-page container text-white">
          <h1 class="text-center mb-4 fw-bolder" style="color: #ff8000;">About Zm-WestCStrike</h1>
      
          <!-- Introduction -->
          <section class="mb-5">
            <p>
              Welcome to <strong>Zm-WestCStrike</strong>, a premier Counter-Strike 1.6 server dedicated to bringing you the ultimate zombie survival experience. Our server is built for players who crave action-packed gameplay, thrilling challenges, and a community-driven environment.
            </p>
          </section>
      
          <!-- Who We Are -->
          <section class="mb-5">
            <h2 style="color: #ff8000;" class="fw-bolder">Who We Are?</h2>
            <p>
              At ZM-WESTCSTRIKE, we are passionate gamers who love the adrenaline and strategy that comes with the Zombie Mode in CS 1.6. We’ve combined custom mods, unique maps, and exciting gameplay features to create a server like no other. Whether you're a seasoned CS veteran or new to the game, our server offers something for everyone.
            </p>
          </section>
      
          <!-- What Makes Us Unique -->
          <section class="mb-5">
            <h2 style="color: #ff8000;" class="fw-bolder">What Makes Us Unique?</h2>
            <ul>
              <li><strong>Diverse Game Modes:</strong> From <em>Hybrid vs Samurai</em> to <em>Plague Mode</em>, our server features a wide variety of thrilling game modes to keep the action fresh and exciting.</li>
              <li><strong>Custom Maps:</strong> Explore a vast selection of hand-picked and custom-built maps designed to challenge your skills and immerse you in the zombie apocalypse.</li>
              <li><strong>Dynamic Gameplay:</strong> Featuring specialized roles like Nemesis Zombies, Survivors, and more, our server ensures no two rounds are ever the same.</li>
              <li><strong>Active Community:</strong> Join a vibrant and friendly community of players who share your passion for CS 1.6 Zombie Mode.</li>
            </ul>
          </section>
      
          <!-- Why Play on Our Server -->
          <section class="mb-5">
            <h2 style="color: #ff8000;" class="fw-bolder">Why Play on ZM-WESTCSTRIKE?</h2>
            <p>
              - <strong>Fast-Paced Action:</strong> Experience the thrill of surviving relentless zombie attacks or turning the tide as the undead.<br>
              - <strong>Constant Updates:</strong> We are always working on improving gameplay, adding new modes, and enhancing your experience.<br>
              - <strong>Fair Play Environment:</strong> Our server is moderated to ensure a fun, competitive, and cheat-free experience for everyone.
            </p>
          </section>
      
          <!-- Join Us -->
          <section class="mb-3 text-center">
            <h2 style="color: #ff8000;" class="fw-bolder">Join Us Today!</h2>
            <p>
              Are you ready to face the horde, fight for survival, or become the ultimate zombie? Join <strong>ZM-WESTCSTRIKE</strong> and immerse yourself in the chaos and fun of CS 1.6 Zombie Mode.
            </p>
            <p>
                If you are a steam user you can join our server by this button!
            </p>
            <a
                href="steam://connect/51.77.72.157:27015"
                class="btn text-white"
                style="background-color: #ff8000;"
                target="_self"
                rel="noopener"
            >
                Join the Server
            </a>
          </section>
        </div>
        <br><br>
    </div>
  </template>
  
  <script>
  export default {
    name: "AboutPage",
  };
  </script>
  
  <style scoped>
  .about-page {
    background-color: #1c1c1c;
    padding: 20px;
    border-radius: 8px;
    line-height: 1.6;
  }
  
  .about-page ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .about-page h2 {
    margin-top: 30px;
  }
  
  .about-page a {
    text-decoration: none;
    font-weight: bold;
  }
  
  .about-page a:hover {
    text-decoration: underline;
  }
  </style>
  